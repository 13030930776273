function padDate(input: number) {
  return String(input).padStart(2, '0');
}

export function convertDateToString(date: Date | null) {
  if (!date) return null;
  const dateObj = new Date(date);
  return [dateObj.getFullYear(), padDate(dateObj.getMonth() + 1), padDate(dateObj.getDate())].join(
    '-',
  );
}

export function convertSecondsToMonth(seconds: number) {
  const month = 2629743; // seconds
  return seconds / month;
}

export function isValidDate(input: any) {
  const inputDate = new Date(input);
  return isNaN(inputDate.getTime()) ? false : !!inputDate.getTime();
}
// converter for use in df-date
export function convertDateToInput(date: Date | null) {
  if (!date) return null;
  const dateFormat = new Date(date);
  return isValidDate(dateFormat) ? dateFormat.toISOString().slice(0, 10) : null;
}

export function dateMinusYear(date: Date, years = 1) {
  const dateObj = new Date(date);
  return dateObj.setFullYear(dateObj.getFullYear() - years);
}

export function convertToDate(input: string | null) {
  if (!input) return null;
  const date = new Date(input);
  const check = Boolean(
    !isNaN(Number(date)) && input != null && [Date, String, Number].includes(input.constructor),
  );

  return check ? date : null;
}

export function setTime(date: Date, time = 12) {
  const d = new Date(date);
  d.setHours(time);
  return d;
}

export function getTime(date?: Date | null) {
  return date ? date.getTime() : 0;
}

export function resetMilliseconds(date: Date | null) {
  date?.setMilliseconds(0);
  return date;
}

export function timeDifference(time1: Date, time2: Date) {
  const diff = time1.getTime() - time2.getTime();
  const hours = Math.floor(diff / 60 / 60 / 1000);
  const minutes = String(Math.floor(diff / 60 / 1000) % 60).padStart(2, '0');
  const seconds = String(Math.floor(diff / 1000) % 60).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}
