import { ref } from 'vue';
import { defineStore } from 'pinia';
import { api, genericApiErrorHandler } from '/@tools/api';

export interface TodoItem {
  todoItemId: number;
  name: string;
  checked: boolean;
  disabled: boolean;
  updatedAt: Date;
  updatedByUserId: number;
}

function mapTodoItem(todo: any): TodoItem {
  return {
    todoItemId: todo.TodoItemId,
    name: todo.Name,
    checked: todo.Checked,
    disabled: todo.Disabled,
    updatedAt: todo.UpdatedAt,
    updatedByUserId: todo.UpdatedByUserId,
  };
}

export const useTodoStore = defineStore('todo', () => {
  const todoList = ref<Map<number, Map<number, TodoItem>>>(new Map());

  function loadTodos(contextId: number) {
    return api
      .get(`/projects/${contextId}/todos`)
      .then(({ data }: { data: Array<any> }) => {
        const list = new Map(data.map((todo: any) => [todo.Id, mapTodoItem(todo)]));

        todoList.value.set(contextId, list);
      })
      .catch(genericApiErrorHandler);
  }

  function updateTodoItem(
    contextId: number,
    todoItemId: number,
    form: { checked: boolean; disabled: boolean },
  ) {
    return api
      .put(`/projects/${contextId}/todos/${todoItemId}`, {
        Checked: form.checked,
        Disabled: form.disabled,
      })
      .then(() => loadTodos(contextId))
      .catch(genericApiErrorHandler);
  }

  return {
    todoList,
    loadTodos,
    updateTodoItem,
  };
});
