import { Opt, OptColumn, OptType } from '/@shared/types/list';
import axios from 'axios';
import { apiBaseUrl, apiArkivBaseUrl, apiIoTBaseUrl } from './config';
import { goldPartners } from '/@features/tenant/tenant.tools';
import { log } from './log';
import { toast } from '/@composables';
import { client } from '/@shared/plugins/auth0';
import { sendError } from '/@plugins/sentry';
import { errorMessages } from '/@molecules/error-center/error-center.vue';

const authHeader = (token: string) => `Bearer ${token}`;

const api = axios.create({ baseURL: apiBaseUrl });
const apiArkiv = axios.create({ baseURL: apiArkivBaseUrl });
const apiIoT = axios.create({ baseURL: apiIoTBaseUrl });

function parser(input: any) {
  // will fail if input is a string that does not contain valid JSON
  return typeof input === 'string' ? JSON.parse(input) : input;
}

const genericApiErrorHandler = async (error: any, custom = null): Promise<any> => {
  if (error.message === 'canceled') {
    log.info('Request canceled', error);
    return;
  } else {
    sendError(error, {
      requestId: error?.response?.headers?.requestid,
      requestMethod: error?.config?.method,
      requestPath: error?.config?.url,
      requestData: error?.config?.data && parser(error?.config?.data),
      message: error?.response?.data,
      error,
    });

    errorMessages.value?.push(custom || error.response);
    throw error;
  }
};

const customApiErrorHandler = async ({
  error,
  data,
  options = { hideToast: false },
}): Promise<any> => {
  if (error.message === 'canceled') {
    log.info('Request canceled', error);
    return;
  }
  sendError(error, {
    error,
    response: error.response,
    data,
  });

  if (!options.hideToast) {
    errorMessages.value.push(custom || error.response);
  }

  throw error;
};

const requestInterceptor = async (config) => {
  // Ignore auth on all requests from public sites
  if (location.pathname.startsWith('/public/')) {
    return config;
  }

  try {
    const token = client?.getTokenSilently();
    if (token) {
      config.headers['Authorization'] = authHeader(await token);
    }

    return config;
  } catch (error) {
    if (error?.message === 'Login required') {
      toast('Mistet tilgang. Logger inn på nytt...');
      await client?.loginWithRedirect({ appState: { target: location.href } });
      return config;
    }

    toast(error);
    return config;
  }
};

const responseErrorInterceptor = async (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 200:
      case 201:
        return error.response;

      case 401: {
        try {
          let token;

          if (!retrying) {
            retrying = true;
            token = client?.getTokenSilently();
          }

          error.config.headers['Authorization'] = authHeader(await token);
          retrying = false;

          return axios(error.config);
        } catch (error) {
          client?.loginWithRedirect({ appState: { target: location.href } });
        }
      }
    }
  }

  throw error;
};

const contentDisposition = (header) => {
  return {
    inline: () => /^inline($|;)/.test(header),
    attachment: () => /^attachment($|;)/.test(header),
    formData: () => /^form\-data($|;)/.test(header),
    filename: () => {
      const match = header?.match(/filename="?([^"\s]*)("?;|"?$)/);
      if (match && match.length > 1) {
        return decodeURI(match[1]);
      }

      return null;
    },
  };
};

let retrying = false;

api.interceptors.request.use(requestInterceptor);
apiArkiv.interceptors.request.use(requestInterceptor);
apiIoT.interceptors.request.use(requestInterceptor);

api.interceptors.response.use(null, responseErrorInterceptor);
apiArkiv.interceptors.response.use(null, responseErrorInterceptor);
apiIoT.interceptors.response.use(null, responseErrorInterceptor);

export function filterQuery(opt: Opt, columns: Array<OptColumn>): URLSearchParams {
  function filterType(columns, columnId, type) {
    const column = columns.find(({ id }) => id === columnId);

    if (column != null) return column.filterKey?.constructor === type;
    return false;
  }

  const searchParams = new URLSearchParams();
  const nFilters = opt.filters.filter(({ columnId }) => filterType(columns, columnId, Number));
  const sFilters = opt.filters.filter(({ columnId }) => filterType(columns, columnId, String));

  if (opt.type == OptType.List) {
    searchParams.append('limit', String(opt.limit));
    searchParams.append('offset', String(opt.offset));
    searchParams.append('orderBy', String(opt.sortBy));
    searchParams.append('orderDesc', String(opt.sortDesc));
  }

  if (opt.type === OptType.Map) {
    searchParams.append('limit', String(100_000)); // 100_000 is max limit from API
  }

  if (opt.search?.length > 0) {
    searchParams.append('search', String(opt.search));
  }

  if (opt.tenantId != null) {
    searchParams.append('partnerId', String(opt.tenantId));
  }

  if (goldPartners.value != null) {
    goldPartners.value.forEach((id) => {
      searchParams.append('tenantIds', String(id));
    });
  }

  if (nFilters.length > 0) {
    const base64 = btoa(
      JSON.stringify(
        nFilters.map(({ columnId, values }) => {
          const column = columns.find(({ id }) => id === columnId);
          return {
            id: column.filterKey,
            values:
              ('filterList' in column || 'getFilterList' in column) != null
                ? values
                : values.map((value) => ([-100, '#EMPTY'].includes(value) ? value : `%${value}%`)),
          };
        }),
      ),
    );
    searchParams.append('pFilterBase64', base64);
  }

  sFilters.forEach(({ values, columnId }) => {
    const column = columns.find(({ id }) => id === columnId);
    values.forEach((value) => searchParams.append(String(column.filterKey), String(value)));
  });

  return searchParams;
}

export function filterQuery2(opt: Opt, columns: Array<any>): URLSearchParams {
  function filterType(columns, columnId, type) {
    const column = columns.find(({ id }) => id === columnId);

    if (column != null) return column.filterKey?.constructor === type;
    return false;
  }

  const searchParams = new URLSearchParams();
  const nFilters = opt.filters.filter(({ columnId }) => filterType(columns, columnId, Number));
  const sFilters = opt.filters.filter(({ columnId }) => filterType(columns, columnId, String));

  if (opt.type == OptType.List) {
    searchParams.append('limit', String(opt.limit));
    searchParams.append('offset', String(opt.offset));
    searchParams.append('orderBy', String(opt.sortBy));
    searchParams.append('orderDesc', String(opt.sortDesc));
  }

  if (opt.type === OptType.Map) {
    searchParams.append('limit', String(100_000)); // 100_000 is max limit from API
  }

  if (opt.search.length > 0) {
    searchParams.append('search', String(opt.search));
  }

  if (opt.tenantId != null) {
    searchParams.append('partnerId', String(opt.tenantId));
  }

  if (goldPartners.value != null) {
    goldPartners.value.forEach((id) => {
      searchParams.append('tenantIds', String(id));
    });
  }

  if (nFilters.length > 0) {
    nFilters.forEach(({ columnId, values }, index) => {
      const column = columns.find(({ id }) => id === columnId);

      searchParams.append(`propertyfilters[${index}].id`, column.filterKey);

      if (column.typeId != null) {
        searchParams.append(`propertyfilters[${index}].typeid`, String(column.typeId));
      }

      values.forEach((value) => {
        searchParams.append(`propertyfilters[${index}].values`, String(value));
      });
    });
  }

  sFilters.forEach(({ values, columnId }) => {
    const column = columns.find(({ id }) => id === columnId);
    values.forEach((value) => searchParams.append(column.filterKey, String(value)));
  });

  return searchParams;
}

function createQueryParams(properties: Map<string, any>, { useGoldPartners = false } = {}) {
  const query = new URLSearchParams();

  [...properties].forEach(([name, value]) => {
    if (value == null || (typeof value === 'string' && value.length === 0)) return;

    if (typeof value === 'object') {
      [...value].forEach((val) => {
        if (!val) return;
        query.append(name, val);
      });
    } else {
      query.append(name, value);
    }
  });

  if (useGoldPartners) {
    [...goldPartners.value].forEach((e) => {
      query.append('tenantIds', `${e}`);
    });
  }

  return query;
}

export {
  api,
  apiArkiv,
  apiIoT,
  genericApiErrorHandler,
  customApiErrorHandler,
  contentDisposition,
  createQueryParams,
};
