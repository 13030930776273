import { ref } from 'vue';
import { defineStore } from 'pinia';
import { api, createQueryParams, genericApiErrorHandler } from '/@tools/api';

type FromApi = any;

export interface Photoreport {
  id: number;
  name: string;
  type: string;
  projectId: number;
  revision: number;
  guid: string;
  updatedAt: Date;
  updatedByUserId: string;
  updatedByUserFullName: string;
  sharedWith: { tenantId: number; tenantName: string }[];
}

export interface Revision {
  id: number;
  nth: number;
  photoreportGuid: string;
  createdAt: Date;
  createdByUserId: number;
  createdByUserFullName: string;
}

interface RemovePhotoFromPhotoReportOptions {
  photoReportGuid: string;
  photoCheckpointGuid: string;
  photoGuid: string;
  photoCheckpointIndex: string;
}

function mapPhotoreportHelper(photoreport: FromApi): Photoreport {
  return {
    id: photoreport.Id,
    name: photoreport.Name,
    type: photoreport.Type,
    projectId: photoreport.ProjectId,
    revision: photoreport.Revision,
    guid: photoreport.PhotoReportGuid,
    updatedAt: new Date(photoreport.ModifiedDateTime),
    updatedByUserId: photoreport.ModifiedByUserId,
    updatedByUserFullName: photoreport.ModifiedByUsername,
    sharedWith: photoreport.SharedWith.map((tenant: FromApi) => ({
      tenantId: tenant.TenantId,
      tenantName: tenant.TenantName,
    })),
  };
}

function mapRevision(revision: FromApi, photoreportGuid: string): Revision {
  return {
    id: revision.Id,
    nth: revision.Revision,
    photoreportGuid,
    createdAt: new Date(revision.CreatedAt),
    createdByUserId: revision.CreatedByUserId,
    createdByUserFullName: revision.CreatedByUserFullName,
  };
}

export const usePhotoreportStore = defineStore('photoreport', () => {
  const photoreports = ref<Map<string, Photoreport>>(new Map());

  function loadPhotoreportsByProjectId(
    projectId: number,
    type: 'system' | undefined = undefined,
  ): Promise<Map<string, Photoreport>> {
    return api
      .get(`/v1/projects/${projectId}/photoreports`, { params: { type } })
      .then(({ data }) => {
        const mapped: [string, Photoreport][] = data.map((pr: FromApi) => [
          pr.PhotoReportGuid,
          mapPhotoreportHelper({ ...pr, Name: type === 'system' ? 'Prosjektbilder' : pr.Name }),
        ]);

        mapped.forEach(([guid, pr]) => {
          photoreports.value.set(guid, pr);
        });

        return new Map(mapped);
      })
      .catch(genericApiErrorHandler);
  }

  const photoreportsJson = ref(new Map());

  function loadSinglePhotoReport(projectId: number, photoReportGuid: string) {
    return api
      .get(`/v1/projects/${projectId}/photoreports/${photoReportGuid}`)
      .then(({ data }) => {
        photoreportsJson.value.set(data.Guid, data);
        return data;
      })
      .catch(genericApiErrorHandler);
  }

  function loadPhotoreportJSONByGuid(
    projectId: number,
    photoreportGuid: string,
    revision: number | null = null,
  ) {
    // Do not save to store, only return. (Big file)
    return api
      .get(
        `/v1/projects/${projectId}/photoreports/${photoreportGuid}${
          revision ? `/revisions/${revision}` : ''
        }`,
      )
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function savePhotoreportJSON(report) {
    return api
      .post(`/v1/projects/${report.ProjectId}/photoreports/${report.Guid}`, report)
      .catch(genericApiErrorHandler);
  }

  function deletePhotoreport(projectId: number, photoreportGuid: string) {
    return api
      .delete(`/v1/projects/${projectId}/photoreports/${photoreportGuid}`)
      .then(() => {
        photoreports.value.delete(photoreportGuid);
      })
      .catch(genericApiErrorHandler);
  }

  function loadDeletedPhotoreportsByProjectId(
    projectId: number,
  ): Promise<Map<string, Photoreport>> {
    return api
      .get(`/v1/projects/${projectId}/photoreports?isDeleted=true`)
      .then(({ data }) => {
        return new Map(
          data.map((photoreport: FromApi) => [
            photoreport.PhotoReportGuid,
            mapPhotoreportHelper(photoreport),
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function removePhotoFromPhotoReport({
    photoReportGuid,
    photoCheckpointGuid,
    photoGuid,
    photoCheckpointIndex,
  }: RemovePhotoFromPhotoReportOptions) {
    const photoReport = photoreportsJson.value.get(photoReportGuid);
    const { ImageGuids, ImageData } = photoReport.PhotoCheckpoints.find(
      (checkpoint) => checkpoint.Guid === photoCheckpointGuid,
    ).PhotoControls[photoCheckpointIndex];

    ImageGuids.splice(
      ImageGuids.findIndex((guid) => guid === photoGuid),
      1,
    );
    ImageData.splice(
      ImageData.findIndex(({ Guid }) => Guid === photoGuid),
      1,
    );
  }

  function restorePhotoreportByGuid(projectId: number, photoreportGuid: string) {
    return api
      .post(`/v1/projects/${projectId}/photoreports/${photoreportGuid}/restore`)
      .then(() => loadPhotoreportsByProjectId(projectId))
      .catch(genericApiErrorHandler);
  }

  function loadPhotoReportImages(projectId: number, projectPhotoReportGuid: string) {
    return api
      .get(`/v1/projects/${projectId}/photoReports/${projectPhotoReportGuid}/images`)
      .then(({ data }) => data)
      .catch(genericApiErrorHandler);
  }

  function loadFileImageByGuid(fileGuid: string) {
    return api
      .get(`files/${fileGuid}/Uri`)
      .then((result) => result.data.uri)
      .catch(genericApiErrorHandler);
  }

  function shareWithPartner({
    partnerId,
    photoreportGuid,
    projectId,
    share = true,
  }: {
    partnerId: number;
    photoreportGuid: string;
    projectId: number;
    share: boolean;
  }) {
    return api
      .post(`/v1/projects/${projectId}/photoreports/${photoreportGuid}/share`, {
        PartnerId: partnerId,
        Share: share,
      })
      .then(async (response) => {
        await loadPhotoreportsByProjectId(projectId);
        return response;
      });
  }

  const revisions = ref<Map<number, Revision>>(new Map());

  function loadRevisionsByPhotoreportGuid(projectId: number, photoreportGuid: string) {
    return api
      .get(`/v1/projects/${projectId}/photoreports/${photoreportGuid}/revisions`)
      .then(({ data }) => {
        data.forEach((revision: FromApi) => {
          revisions.value.set(revision.Id, mapRevision(revision, photoreportGuid));
        });
      })
      .catch(genericApiErrorHandler);
  }

  function loadPhotoreportImageReferences(projectId: number, photoreportGuid: string) {
    return api
      .get(`/v2/projects/${projectId}/photoreports/${photoreportGuid}/findimagereferences`)
      .then(({ data }) => {
        return data.filter(
          ({ PhotoCheckpoint, PhotoControlpoint }) => PhotoCheckpoint && PhotoControlpoint,
        );
      })
      .catch(genericApiErrorHandler);
  }

  const checkpointTemplates = ref(new Map());

  function loadCheckpointTemplates(gridownerId = '') {
    const queries = createQueryParams(new Map([['gridownerId', gridownerId]]));
    return api
      .get(`/v1/templates/photocheckpoints?${queries}`)
      .then(({ data }) => {
        data.forEach((cp) => {
          checkpointTemplates.value.set(cp.Id, cp);
        });

        return data;
      })
      .catch(genericApiErrorHandler);
  }

  return {
    photoreports,
    loadPhotoreportsByProjectId,

    photoreportsJson,
    loadSinglePhotoReport,
    loadPhotoreportJSONByGuid,
    savePhotoreportJSON,

    removePhotoFromPhotoReport,
    deletePhotoreport,
    loadDeletedPhotoreportsByProjectId,
    restorePhotoreportByGuid,

    loadPhotoReportImages,
    loadFileImageByGuid,

    shareWithPartner,

    revisions,
    loadRevisionsByPhotoreportGuid,
    loadPhotoreportImageReferences,

    checkpointTemplates,
    loadCheckpointTemplates,
  };
});
