import * as structuredLog from 'structured-log';
import seqSink from 'structured-log-seq-sink';
import config from './config';
import { sendError } from '/@plugins/sentry';

export enum LogType {
  Created,
  Updated,
  Deleted,
  NoteCreated,
  NoteUpdated,
  NoteDeleted, // Finnes ikke
  FileCreated,
  FileDeleted,
  LoanCreated,
  LoanDeleted,
  ChecklistCreated, // Finnes ikke
  ChecklistUpdated, // Finnes ikke
  ChecklistDeleted, // Finnes ikke
  Shared,
}

export function mapLogType(type) {
  switch (type) {
    case 'Add':
    case 'ble lagt til':
    case 'created':
      return LogType.Created;

    case 'ble oppdatert':
    case 'Update':
    case 'updated':
      return LogType.Updated;

    case 'Delete':
    case 'Deleted':
    case 'deleted':
      return LogType.Deleted;

    case 'noteCreated':
      return LogType.NoteCreated;

    case 'noteUpdated':
      return LogType.NoteUpdated;

    case 'fileAdded':
      return LogType.FileCreated;

    case 'fileDeleted':
      return LogType.FileDeleted;

    case 'loanCreated':
      return LogType.LoanCreated;

    case 'loanDeleted':
      return LogType.LoanDeleted;

    case 'Share':
      return LogType.Shared;

    default:
      return null;
  }
}

let log = structuredLog.configure().writeTo(new structuredLog.ConsoleSink()).create();

if (config?.seqUrl == null) {
  sendError('seqUrl does not exist', {
    config,
    //@ts-expect-error
    appConfig: window.appConfig,
  });
} else if (typeof seqSink === 'function') {
  log = structuredLog
    .configure()
    .writeTo(
      new structuredLog.BatchedSink(
        seqSink({
          url: config.seqUrl,
          apiKey: config.seqKey,
        }),
      ),
      {},
    )
    .enrich(() => {
      const user = JSON.parse(localStorage.getItem('currentUser'));

      return {
        AppVersion: config.buildVersion,
        UserId: user?.id ?? null,
        User: user?.email ?? null,
        TenantId: user?.tenant.id ?? null,
        Tenant: user?.tenant.name ?? null,
        Location: location.href,
      };
    })
    .create();
}

export { log };
