import { ref, onMounted, onUnmounted, toRaw } from 'vue';
import { awaitUser2, useUserStore } from '/@features/user';
import { useProjectStore } from '/@features/project';
import { useDatabase } from '/@composables';
import { api } from '/@shared/tools/api';
import { ChecklistTemplates, Modules } from '/@shared/types/ids';
import { cloneDeep } from 'lodash-es';

export function useOffline() {
  const isOnline = ref(navigator.onLine);

  function handleOnline() {
    isOnline.value = navigator.onLine;
  }

  onMounted(() => {
    addEventListener('online', handleOnline);
    addEventListener('offline', handleOnline);
  });

  onUnmounted(() => {
    removeEventListener('online', handleOnline);
    removeEventListener('offline', handleOnline);
  });

  const storageQuota = ref({
    quota: 0,
    usage: 0,
    update: async () => {
      if (!navigator.storage.estimate) return Promise.resolve();
      return await navigator.storage.estimate().then((info) => {
        const convertToMb = (num: number | undefined) => (num ? Math.floor(num / 1_000_000) : 0);
        storageQuota.value.quota = convertToMb(info.quota);
        storageQuota.value.usage = convertToMb(info.usage);
      });
    },
  });

  storageQuota.value.update();

  const database = useDatabase();

  const userStore = useUserStore();

  function saveUserOffline() {
    const user = userStore.user;
    return database.add('user', user.id, toRaw(user));
  }

  const projectStore = useProjectStore();

  function saveProjectOffline(projectId: number) {
    return Promise.all([
      projectStore.loadProjectById(projectId),
      projectStore.newLoadProjectDetailsById(projectId),
    ])
      .then(([project, properties]) => {
        return Promise.all([
          database.add('projects', project.id, project),
          [...properties.values()].map((p) =>
            database.add('projectProperties', `${project.id}-${p.id}`, p),
          ),
        ]);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function saveOfflineChecklistTemplates() {
    const templateIds = [ChecklistTemplates.SjaFseRisiko];

    return Promise.all(templateIds.map((id) => api.get(`v2/checklisttemplates/${id}`))).then(
      (res) =>
        database.addBulk(
          'checklistTemplates',
          res.map(({ data }) => ({ ...data, id: data.Checklist.ChecklistTemplateId })),
          true,
        ),
    );
  }

  function saveOfflineProjectTypes() {
    return projectStore
      .loadProjectTypes({ moduleId: Modules.Energi2 })
      .then((res) => database.addBulk('projectTypes', cloneDeep([...res.values()]), true));
  }

  async function setupOffline() {
    if (!isOnline.value) return;

    await awaitUser2;

    return Promise.all([
      saveUserOffline(),
      userStore.loadFavoriteProjects().then(async () => {
        await Promise.all([database.clear('projects'), database.clear('projectProperties')]);
        return Promise.all(
          [...userStore.user.favoriteProjects.keys()]
            .slice(0, 10)
            .map((id) => saveProjectOffline(id)),
        );
      }),
      saveOfflineChecklistTemplates(),
      saveOfflineProjectTypes(),
    ]);
  }

  return {
    isOnline,
    storageQuota,
    saveUserOffline,
    saveProjectOffline,
    setupOffline,
    database,
  };
}
