import Dexie, { type EntityTable } from 'dexie';
import { QueueItemFull } from './useQueue';
import { Property } from '/@types/properties';
import { User } from '/@features/user/user.types';
import { sub } from 'date-fns';
import { Project, ProjectType } from '/@features/project/project.types';

const db = new Dexie('dfDatabase') as Dexie & {
  user: EntityTable<User, 'id'>;
  projects: EntityTable<Project, 'id'>;
  projectProperties: EntityTable<Property, 'uniqueId'>;
  checklistTemplates: EntityTable<any, 'id'>;
  projectTypes: EntityTable<ProjectType, 'id'>;
  queue: EntityTable<QueueItemFull, 'guid'>;
};

db.version(2).stores({
  user: 'id',
  projects: 'id',
  projectProperties: 'uniqueId',
  checklistTemplates: 'id',
  projectTypes: 'id',
  queue: 'guid, createdAt',
});

type Table =
  | 'user'
  | 'projects'
  | 'projectProperties'
  | 'checklistTemplates'
  | 'projectTypes'
  | 'queue';

type TableItem = User | Project | Property | ProjectType | QueueItemFull;

export function useDatabase() {
  function get(table: Table) {
    return db[table].toArray();
  }

  function add(table: Table, id: number | string, item: TableItem) {
    return db[table].put(Dexie.deepClone(item), id);
  }

  async function addBulk(table: Table, items: TableItem[], clear = false) {
    if (clear) await db[table].clear();
    return Promise.all(items.map((i) => db[table].put(Dexie.deepClone(i), i.id)));
  }

  function remove(table: Table, id: number | string) {
    return db[table].delete(id);
  }

  function removeOld(table: Table) {
    const oneWeekAgo = sub(new Date(), { weeks: 1 });

    return db[table].where('createdAt').below(oneWeekAgo).delete();
  }

  function clear(table: Table) {
    return db[table].clear();
  }

  return { get, add, addBulk, remove, removeOld, clear };
}
